<template>
    <div>
        <v-sparkline type="bar" :value="value" line-width="0.99" height="5" :gradient="gradient"></v-sparkline>
        <v-sparkline type="bar" :labels="labels" :value="hourRuler" line-width="0.99" height="5"></v-sparkline>
    </div>
</template>

<script>
export default {
    name: "TimeChart",
    components: {},
    props: ["imageList"],
    data() {
        return {
            frameSize: 10, //1分、5分、10分、15分、30分、60分
            timeGuageMax: 24 * (60 / 10), // 1コマ ＝ 10分
            timerChartData: [],
            //---------------------------
            gradient: ["red"],
            labels: [
                // "0"," "," "," "," "," ",
                // "1"," "," "," "," "," ",
                // "2"," "," "," "," "," ",
                // "3"," "," "," "," "," ",
                // "4"," "," "," "," "," ",
                // "5"," "," "," "," "," ",
                // "6"," "," "," "," "," ",
            ],
            value: [
                // 1, 1, 1, 1, 1, 1,
                // 2, 2, 2, 2, 2, 2,
                // 3, 3, 3, 3, 3, 3,
                // 4, 4, 4, 4, 4, 4,
                // 5, 5, 5, 5, 5, 5,
                // 6, 6, 6, 6, 6, 6,
            ],
            hourRuler: [
                // 1, 1, 1, 1, 1, 1,
            ],
            //---------------------------
        };
    },
    //===========================================================================
    created: function () {
        this.makeTimeChartData(this.imageList);
    },
    //===========================================================================
    watch: {
        imageList: async function (newImageList) {
            await new Promise((r) => setTimeout(r, 500));
            this.makeTimeChartData(newImageList);
        },
    },
    //===========================================================================
    methods: {
        makeTimeChartData(newImageList) {
            //--------------------------------------------------
            this.hourRuler = new Array(this.timeGuageMax).fill(-1);
            for (let i = 0; i < 24; i++) {
                this.hourRuler[i * 6] = -2;
            }
            //--------------------------------------------------
            this.labels = new Array(this.timeGuageMax).fill(" ");
            let hh = 1;
            for (let i = 1; i < 24; i++) {
                this.labels[i * 6] = hh++;
            }
            //--------------------------------------------------
            this.timerChartData = new Array(this.timeGuageMax).fill(0);
            for (let i in newImageList) {
                if (String(newImageList[i])) {
                    let frameNo = Math.floor(i / (60 * this.frameSize));
                    this.timerChartData[frameNo] = 10;
                }
            }
            this.value = Object.assign([], this.timerChartData);
            //--------------------------------------------------
        },
    },
    //===========================================================================
};
</script>
