<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-text-field outlined dense hide-details="false" v-model="keywordFilter" label="キーワードフィルター" @change="buildDeviceTree()" v-show="!isLoading"></v-text-field>
        <!-- ============================================= -->
        <div v-for="obj in deviceTree" :key="obj.device.id">
            <v-card class="blue darken-2 my-3">
                <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="true" :continuous="false">
                    <v-carousel-item>
                        <v-card-text class="white--text pa-1">
                            <h2 class="ma-2 text-center">
                                {{ obj.device.name }}
                            </h2>
                            <!-- ============================================= -->
                            <v-alert class="ma-3" type="success" v-if="obj.sensor.float_sw == '0'"><h2>通常水位</h2></v-alert>
                            <v-alert class="ma-3" type="error" v-if="obj.sensor.float_sw == '1'"><h2>危険水位</h2></v-alert>
                            <!-- ============================================= -->
                            <h5 class="text-right white--text mx-2">
                                {{ dateFormat(obj.device.alived_at, "Y年M月D日 HH:mm") }}
                                <v-icon color="yellow" v-show="chkAlive(obj.device.alived_at)">mdi-alert</v-icon>
                            </h5>
                        </v-card-text>
                    </v-carousel-item>
                    <v-carousel-item>
                        <v-container>
                            <h2 class="white--text ma-2">{{ obj.device.name }}</h2>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: obj.device.id } }" color="success"><v-icon>mdi-chart-line</v-icon></v-btn>
                                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: obj.device.id } }" color="yellow"><v-icon>mdi-camera-outline</v-icon></v-btn>
                                    <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: obj.device.id } }" color="error" v-show="obj.device.has_videocam"><v-icon>mdi-video-outline</v-icon></v-btn>
                                    <!--
                                    <v-btn class="ma-1" color="cyan lighten-2">加速度</v-btn>
                                    <v-btn class="ma-1" color="purple lighten-2">自動昇降</v-btn>
                                    -->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </div>
    </v-container>
</template>

<script>
// @ is an alias to /src

export default {
    name: "DevicesList",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            dbChartConfigs: [],
            deviceTree: [],
            keywordFilter: "",
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.isLoading = true;
        //-----------------------
        //非同期でAxiosGet
        const promiseDevice = this.getDevices();
        const promiseSite = this.getSites();
        const promiseSensor = this.getLastSensings();
        const promiseConfig = this.getChartConfigs();
        //-----------------------
        //上記の終了を待つ
        await promiseDevice;
        await promiseSite;
        await promiseSensor;
        await promiseConfig;
        //-----------------------
        //上記が全て取得終了したタイミングで、配列マージする
        //await new Promise((r) => setTimeout(r, 500));
        await this.buildDeviceTree();
        //-----------------------
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                //params: { "_order[id]": "desc" },
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getLastSensings() {
            await this.axios({
                method: "GET",
                url: "/web/api/last_sensings",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbLastSensings = response.data.json;
            });
        },
        //====================================================
        async getChartConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/chart_configs",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbChartConfigs = response.data.json;
            });
        },
        //====================================================
        buildDeviceTree() {
            //----------------------------------------------
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            this.deviceTree = [];
            for (let idx in this.dbDevices) {
                let objDevice = JSON.parse(JSON.stringify(this.dbDevices[idx]));
                let device_id = objDevice.id;
                let site_id = objDevice.site_id;
                let objSite = {};
                let objSensor = {};
                let objConfig = {};
                for (let idx in this.dbSites) {
                    if (site_id == this.dbSites[idx].id) {
                        objSite = this.dbSites[idx];
                        break;
                    }
                }
                for (let idx in this.dbLastSensings) {
                    if (device_id == this.dbLastSensings[idx].device_id) {
                        objSensor = this.dbLastSensings[idx];
                        break;
                    }
                }
                for (let idx in this.dbChartConfigs) {
                    if (device_id == this.dbChartConfigs[idx].device_id) {
                        objConfig = this.dbChartConfigs[idx];
                        break;
                    }
                }
                //------------------------------------
                // キーワードフィルターが「空」or「デバイス名」「組織名」に含まれた場合は表示する。
                let isDisp = false;
                if (!this.keywordFilter) isDisp = true;
                if (String(objDevice.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.pref).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.city).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (isDisp) {
                    objDevice.rating = rating[device_id] ? rating[device_id] : 0;
                    this.deviceTree.push({
                        device: objDevice,
                        site: objSite,
                        sensor: objSensor,
                        config: objConfig,
                    });
                }
            }
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            this.deviceTree.sort(function (a, b) {
                let ret = 0;
                if (a.device.rating > b.device.rating) ret = -1;
                if (a.device.rating < b.device.rating) ret = 1;
                if (a.device.rating == b.device.rating) ret = 0;
                return ret;
            });
            //--------------------------------------------------
        },
        //====================================================
        dateFormat(strDate, format) {
            return this.$moment(strDate).format(format);
        },
        //====================================================
        roundNumber(val) {
            return Math.round(val * 10) / 10;
        },
        //====================================================
        chkAlive(alived_at) {
            if (!alived_at) return false;
            let now = this.$moment().add(-2, "hours");
            let alive = this.$moment(alived_at);
            if (now < alive) return false;
            return true;
        },
        //====================================================
    },
};
</script>
