<template>
    <div>
        <v-container>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
            </v-overlay>
            <!-- ============================================= -->
            <div class="ma-5"></div>
            <v-row>
                <v-col cols="12" v-for="(item, idx) in deviceTree" :key="idx" v-show="nameFilter(item.site_pref + item.site_name + item.device_name)">
                    <v-card class="blue darken-3">
                        <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="true" :continuous="false">
                            <v-carousel-item>
                                <v-card-text class="white--text">
                                    <h2 class="ma-2">{{ item.device_name }}</h2>
                                    <v-row>
                                        <v-col>
                                            <h5>{{ item.site_pref }}</h5>
                                            <h5>{{ item.site_name }}</h5>
                                        </v-col>
                                        <v-col align-self="end">
                                            <h5 class="text-right">{{ item.date }}</h5>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-carousel-item>
                            <v-carousel-item>
                                <v-container>
                                    <h2 class="white--text ma-2">{{ item.device_name }}</h2>
                                    <v-row>
                                        <v-col class="text-center">
                                            <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: item.device_id } }" color="success"><v-icon>mdi-chart-line</v-icon></v-btn>
                                            <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: item.device_id } }" color="yellow"><v-icon>mdi-camera-outline</v-icon></v-btn>
                                            <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: item.device_id } }" color="error" v-show="item.device.has_videocam"><v-icon>mdi-video-outline</v-icon></v-btn>
                                            <!--
                                            <v-btn class="ma-1" color="cyan lighten-2">加速度</v-btn>
                                            <v-btn class="ma-1" color="purple lighten-2">自動昇降</v-btn>
                                            -->
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-carousel-item>
                        </v-carousel>
                        <v-responsive :aspect-ratio="16 / 9">
                            <img :src="item.jpgImg" width="100%" />
                            <!-- <v-img fluid :src="item.jpgImg" /> -->
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-show="loadingMax > loadingCount">
                    <v-btn block @click="buildDeviceTree()" color="success">さらに表示</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
export default {
    name: "DevicesCamera",
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            keywordFilter: "",
            dbSites: [],
            dbDevices: [],
            deviceTree: [
                //{ jpgImg: "", date: "", device_id: "", device_name: "", site_name: "", site_pref: ""}
            ],
            bufferImages: [],
            filter: null,
            loadingCount: 0,
            loadingMax: 0,
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.isLoading = true;
        await this.getSites();
        await this.getDevices();
        await this.buildDeviceTree();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    watch: {},
    //========================================================
    methods: {
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                //params: { is_active: 1, "_order[id]": "desc" },
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then(async (response) => {
                this.dbDevices = response.data.json;
                this.loadingMax = this.dbDevices.length;
            });
        },
        //====================================================
        async buildDeviceTree() {
            //----------------------------------------------
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            for (let idx in this.dbDevices) {
                let device_id = this.dbDevices[idx].id;
                this.dbDevices[idx]["rating"] = rating[device_id] ? rating[device_id] : 0;
            }
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            this.dbDevices.sort(function (a, b) {
                let ret = 0;
                if (a.rating > b.rating) ret = -1;
                if (a.rating < b.rating) ret = 1;
                if (a.rating == b.rating) ret = 0;
                return ret;
            });
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            let startCount = this.loadingCount;
            let endCount = this.loadingCount + 8;
            this.loadingCount = this.loadingCount + 8;
            for (let idx in this.dbDevices) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.dbDevices[idx].id;
                    let device_name = this.dbDevices[idx].name;
                    let site_name = this.getSiteName(this.dbDevices[idx].site_id);
                    let site_pref = this.getSitePref(this.dbDevices[idx].site_id);
                    let deviceRating = rating[device_id] ? rating[device_id] : 0;
                    this.deviceTree.push({
                        device_id: device_id,
                        rating: deviceRating,
                        jpgImg: "", //window.URL.createObjectURL(response.data),
                        date: "---", //response.headers["x-image-date"],
                        device_name: device_name,
                        site_name: site_name,
                        site_pref: site_pref,
                        device: JSON.parse(JSON.stringify(this.dbDevices[idx])),
                    });
                }
            }
            for (let idx in this.deviceTree) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.deviceTree[idx].device_id;
                    this.getLastImage(device_id, this.deviceTree[idx]);
                }
            }
            //--------------------------------------------------
        },
        //====================================================
        getLastImage(device_id, imgObj) {
            this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/last-image/" + device_id + "/",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    imgObj.jpgImg = window.URL.createObjectURL(response.data);
                    imgObj.date = response.headers["x-image-date"];
                    //this.deviceTree = Object.assign([], this.deviceTree);
                })
                .catch((err) => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        nameFilter(device_name) {
            if (!this.filter) return true;
            if (device_name.indexOf(this.filter) == -1) return false;
            return true;
        },
        //====================================================
        getSiteName(site_id) {
            let site_name = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_name = this.dbSites[i].name;
            }
            return site_name;
        },
        //====================================================
        getSitePref(site_id) {
            let site_pref = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_pref = this.dbSites[i].pref + " " + this.dbSites[i].city;
            }
            return site_pref;
        },
        //====================================================
    },
    //========================================================
};
</script>
