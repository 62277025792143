<template>
    <v-container fluid>
        <div class="ma-5 pa-5"></div>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Jje0DdnBXeg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </v-container>
</template>

<script>
export default {
    name: "Manual",
    //========================================================
    data() {
        return {
            //---------------------
            // windowWidth: window.innerWidth, // 画面サイズ
            // windowHeight: window.innerHeight, //画面サイズ
            //---------------------
            windowWidth: 560, // 画面サイズ
            windowHeight: 315, //画面サイズ
            //---------------------
        };
    },
    //========================================================
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        handleResize: function () {
            //this.windowWidth = window.innerWidth;
            //this.windowHeight = window.innerHeight;
            //----------------
            let rate = 315 / 560;
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerWidth * rate;
        },
        //====================================================
    },
};
//<iframe width="560" height="315" src="https://www.youtube.com/embed/QgM3xzdYnDw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//<iframe width="560" height="315" src="https://www.youtube.com/embed/Jje0DdnBXeg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</script>
