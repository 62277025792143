<template>
    <v-container>
        <!-- ============================================= -->
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-row>
            <v-col class="text-right">
                <v-btn class="success" @click="submitRating()">確定</v-btn>
            </v-col>
        </v-row>
        <div v-for="obj in deviceTree" :key="obj.device.id">
            <v-card color="blue lighten-4 my-3 pa-3">
                <h2 class="text-center">{{ obj.device.name }}</h2>
                <h5>{{ obj.site.pref }} {{ obj.site.city }}</h5>
                <h5>{{ obj.site.name }}</h5>
                <div class="text-center">
                    <v-rating v-model="obj.device.rating" clearable dense background-color="pink lighten-5" color="pink lighten-2" size="48" empty-icon="mdi-heart-outline" full-icon="mdi-heart" half-icon="mdi-heart-half-full"></v-rating>
                </div>
            </v-card>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Favorites",
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            dbChartConfigs: [],
            deviceTree: [],
            keywordFilter: "",
            isLoading: false,
            rating: 4,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.isLoading = true;
        //-----------------------
        //非同期でAxiosGet
        const promiseDevice = this.getDevices();
        const promiseSite = this.getSites();
        //-----------------------
        //上記の終了を待つ
        await promiseDevice;
        await promiseSite;
        //-----------------------
        //上記が全て取得終了したタイミングで、配列マージする
        //await new Promise((r) => setTimeout(r, 500));
        await this.buildDeviceTree();
        //-----------------------
        this.isLoading = false;
        //-----------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    methods: {
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                //params: { "_order[id]": "desc" },
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        // 表示用データに変換
        //====================================================
        buildDeviceTree() {
            let rating = this.$localStorage.get("rating");
            rating = rating ? JSON.parse(rating) : {};
            //----------------------------------------------
            this.deviceTree = [];
            for (let idx in this.dbDevices) {
                let objDevice = JSON.parse(JSON.stringify(this.dbDevices[idx]));
                let device_id = objDevice.id;
                let site_id = objDevice.site_id;
                let objSite = {};
                for (let idx in this.dbSites) {
                    if (site_id == this.dbSites[idx].id) {
                        objSite = this.dbSites[idx];
                        break;
                    }
                }
                //------------------------------------
                // キーワードフィルターが「空」or「デバイス名」「組織名」に含まれた場合は表示する。
                let isDisp = false;
                if (!this.keywordFilter) isDisp = true;
                if (String(objDevice.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.name).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.pref).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (String(objSite.city).toLowerCase().indexOf(this.keywordFilter.toLowerCase(), 0) > -1) isDisp = true;
                if (isDisp) {
                    objDevice.rating = rating[device_id] ? rating[device_id] : 0;
                    this.deviceTree.push({
                        device: objDevice,
                        site: objSite,
                    });
                }
            }
            //--------------------------------------------------
            // お気に入り順に、並べ替え
            this.deviceTree.sort(function (a, b) {
                let ret = 0;
                if (a.device.rating > b.device.rating) ret = -1;
                if (a.device.rating < b.device.rating) ret = 1;
                if (a.device.rating == b.device.rating) ret = 0;
                return ret;
            });
            //--------------------------------------------------
        },
        //====================================================
        // Ratingをローカルストレージに保存
        //====================================================
        async submitRating() {
            let saveData = {};
            for (let obj of this.deviceTree) {
                let id = obj.device.id;
                let rating = obj.device.rating;
                if (rating > 0) saveData[id] = rating;
            }
            this.$localStorage.set("rating", JSON.stringify(saveData));
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 2000));
            this.buildDeviceTree();
            this.isLoading = false;
        },
        //====================================================
    },
};
</script>
