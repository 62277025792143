<template>
    <v-container>
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <h2 class="d-inline">{{ deviceName }}</h2>
        <!-- ============================================= -->
        <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="getImageList()"></v-date-picker>
        </v-menu>
        <!-- ============================================= -->
        <img :src="jpgImg" width="100%" />

        <v-divider></v-divider>
        <v-card elevation="24">
            <h3 class="text-center">{{ dispCursorTime() }}</h3>
            <v-slider :min="0" :max="86400 - 1" v-model="cursor_time" dense hide-details></v-slider>
            <div class="pa-1">
                <timeline :imageList="imageList"></timeline>
            </div>
        </v-card>
        <v-divider></v-divider>
        <v-card class="text-center py-3">
            <v-btn class="ml-1" color="info" @click="slideChange(-900)"><v-icon>mdi-step-backward-2</v-icon></v-btn>
            <v-btn class="ml-1" color="info" @click="slideChange(-1)"><v-icon>mdi-step-backward</v-icon></v-btn>
            <v-btn class="ml-1" :color="isPlay ? 'error' : 'info'" @click="slidePlay()"><v-icon>mdi-play-pause</v-icon></v-btn>
            <v-btn class="ml-1" color="info" @click="slideChange(1)"><v-icon>mdi-step-forward</v-icon></v-btn>
            <v-btn class="ml-1" color="info" @click="slideChange(900)"><v-icon>mdi-step-forward-2</v-icon></v-btn>
        </v-card>
        <!-- ============================================= -->
    </v-container>
</template>

<style scoped>
.navi-orange-on {
    font-weight: bold;
    color: orange; /*文字色*/
    padding: 0.3em 0; /*上下の余白*/
    border-bottom: solid 5px orange; /*線の種類（二重線）太さ 色*/
}
.navi-orange-off {
    font-weight: bold;
    color: #aaaaaa; /*文字色*/
    padding: 0.3em 0; /*上下の余白*/
    border-bottom: solid 5px #dddddd; /*線の種類（二重線）太さ 色*/
}

img {
    background-color: lightgray;
    /* height: 60vh; */
    /* width: auto; */
    /* object-fit: contain; */
    object-fit: contain;
}
.pinch-zoom {
    height: 60vh;
}
</style>

<script>
import Timeline from "@/components/CompTimeline";

export default {
    name: "Playback",
    components: {
        Timeline,
    },
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: undefined,
            dbDevices: undefined,
            cursor_time: 0,
            jpgImg: "",
            imageProps: { blank: true },
            imageList: [],
            jpgSec: 1000,
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            isPlay: false,
            aspect: "16:9",
            isLoading: false,
            postResponse: [],
        };
    },
    //===========================================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.device_id = this.$route.params.device_id;
        await this.getDevices();
        await this.getImageList();
    },
    //===========================================================================
    beforeDestroy: function () {},
    //===========================================================================
    mounted: function () {},
    //===========================================================================
    watch: {
        $route: async function (to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.getDevices();
                await this.getImageList();
            }
        },
    },
    //===========================================================================
    computed: {
        deviceName: function () {
            let ret = "";
            for (let idx in this.dbDevices) {
                let id = this.dbDevices[idx].id;
                if (id == this.device_id) ret = this.dbDevices[idx].name;
            }
            return ret;
        },
    },
    //===========================================================================
    methods: {
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        slidePlay: async function () {
            if (this.isPlay == true) {
                this.isPlay = false;
            } else {
                this.isPlay = true;
                while (this.isPlay) {
                    await this.slideChange(+1);
                    await new Promise((r) => setTimeout(r, 300));
                    if (this.cursor_time > 86400 - 100) this.isPlay = false;
                }
            }
        },
        //====================================================
        slideChange: async function (seconds) {
            let nextCursor = Number(this.cursor_time) + Number(seconds);
            while (0 < nextCursor && nextCursor < 86400) {
                if (this.imageList[nextCursor]) break;
                if (seconds == 0) break;
                if (seconds > 0) nextCursor++;
                else nextCursor--;
            }
            if (nextCursor < 0) {
                return true;
            }
            if (nextCursor > 86400 - 1) {
                return true;
            }
            this.cursor_time = nextCursor;
            this.getImage();
            //await new Promise(r => setTimeout(r, 5000));
        },
        //====================================================
        dispCursorTime: function () {
            const hh = (this.cursor_time / 3600) | 0; // 小数点切り捨て
            const mm = ((this.cursor_time - hh * 3600) / 60) | 0; // 小数点切り捨て
            const ss = this.cursor_time % 60; // 余り
            return ("00" + hh).slice(-2) + ":" + ("00" + mm).slice(-2) + ":" + ("00" + ss).slice(-2);
        },
        //====================================================
        async getImageList() {
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 1000));
            await this.axios({
                method: "GET",
                url: "/web/api/rapid-image-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    this.imageList = new Array(86400).fill("");
                    let dateAry = response.data.json;
                    for (let i in dateAry) {
                        let hh = this.$moment(dateAry[i]).hours();
                        let mm = this.$moment(dateAry[i]).minute();
                        let ss = this.$moment(dateAry[i]).second();
                        let aryNo = hh * 3600 + mm * 60 + ss;
                        this.imageList[aryNo] = dateAry[i];
                    }
                    this.isLoading = false;
                    this.isPlay = false;
                    this.cursor_time = 0;
                    this.slideChange(1);
                })
                .catch((err) => {
                    this.isLoading = false;
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        getImage() {
            const jpgKey = this.imageList[this.cursor_time];
            if (!jpgKey) {
                this.jpgImg = "";
                this.imageProps = { blank: true, width: 16, height: 9 };
                return false;
            } else {
                this.imageProps = { blank: false };
            }
            this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/rapid-image/" + this.device_id + "/" + jpgKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    this.jpgImg = window.URL.createObjectURL(response.data);
                })
                .catch((err) => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
    },
};
</script>
