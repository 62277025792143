<template>
    <div>
        <v-container>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
            </v-overlay>
            <!-- ============================================= -->
            <h2>{{ device_name }}</h2>
            <v-divider class="mb-3"></v-divider>
            <!-- ============================================= -->
            <v-row>
                <v-col cols="9">
                    <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="dateChange()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3" class="text-right">
                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: device_id } }" color="success"><v-icon>mdi-chart-line</v-icon></v-btn>
                    <!--
                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: device_id } }" color="yellow"><v-icon>mdi-camera-outline</v-icon></v-btn>
                    -->
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" v-for="(item, idx) in dispImages" :key="idx">
                    <v-card class="blue darken-2">
                        <v-card-text class="white--text">
                            <v-row>
                                <v-col>
                                    <h3>{{ item.device_name }}</h3>
                                </v-col>
                                <v-col>
                                    <h5 class="white--text text-right">{{ item.date }}</h5>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-responsive :aspect-ratio="16 / 9">
                            <img :src="item.jpgImg" width="100%" />
                            <!-- <v-img fluid :src="item.jpgImg" /> -->
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-show="loadingMax > loadingCount">
                    <v-btn block @click="imageLoad()" color="success">さらに表示</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "DeviceImage",
    //========================================================
    data() {
        return {
            jwt: "",
            device_id: 0,
            device_name: "",
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            imageList: [],
            dispImages: [],
            dayImageList: [
                //{ jpgImg: "", date: "", device_id: "", device_name: "" }
            ],
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: "",
            },
            loadingCount: 0,
            loadingMax: 0,
            aspect: "16:9",
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        //------------------------------------------
        if (this.$route.params.device_id) this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;
        //------------------------------------------
        await this.getDevices();
        await this.getImageList();
        await this.imageLoad();
        //------------------------------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    watch: {},
    //========================================================
    methods: {
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { id: this.device_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    if (response.data.json[0]) {
                        this.dbDevices = response.data.json[0];
                        this.device_id = this.dbDevices.id;
                        this.device_name = this.dbDevices.name;
                    }
                })
                .catch((err) => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async getImageList() {
            this.dayImageList = [];
            this.loadingCount = 0;
            this.loadingMax = 0;
            this.dispImages = [];
            await this.axios({
                method: "GET",
                url: "/web/api/image-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then(async (response) => {
                    this.imageList = response.data.json;
                    this.imageList = this.imageList.reverse();
                    this.dayImageList = [];
                    for (let idx in this.imageList) {
                        let dt = this.imageList[idx];
                        if (!dt) continue;
                        this.dayImageList.push({
                            jpgImg: "",
                            date: dt,
                            device_id: this.device_id,
                            device_name: this.device_name,
                        });
                    }
                    this.loadingMax = this.dayImageList.length;
                })
                .catch((err) => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async imageLoad() {
            let startCount = this.loadingCount;
            let endCount = this.loadingCount + 8;
            this.loadingCount = this.loadingCount + 8;
            for (let idx in this.dayImageList) {
                if (startCount <= idx && idx < endCount) {
                    this.dispImages.push(this.dayImageList[idx]);
                }
            }
            for (let idx in this.dispImages) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.dispImages[idx].device_id;
                    let jpgKey = this.dispImages[idx].date;
                    this.getImage(device_id, jpgKey, this.dispImages[idx]);
                }
            }
        },
        //====================================================
        async getImage(device_id, jpgKey, imgObj) {
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/" + device_id + "/" + jpgKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    imgObj.jpgImg = window.URL.createObjectURL(response.data);
                    imgObj.date = response.headers["x-image-date"];
                })
                .catch((err) => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async dateChange() {
            await new Promise((r) => setTimeout(r, 500));
            await this.getImageList();
            await this.imageLoad();
        },
        //====================================================
    },
    //========================================================
};
</script>
