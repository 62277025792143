<template>
    <div>
        <v-overlay :value="isLoading"></v-overlay>
        <v-container class="outer-div">
            <v-row>
                <v-col></v-col>
                <v-col><v-img :src="LogoImg" width="300"></v-img></v-col>
                <v-col></v-col>
            </v-row>
            <!-- =============================================== -->
            <v-row>
                <v-col>
                    <div class="px-2">
                        <v-text-field solo v-model="username" placeholder="アカウント"></v-text-field>
                        <v-text-field solo v-model="password" placeholder="パスワード" type="password"></v-text-field>
                        <v-btn block color="primary darken-4" @click="jwtauth()" class="pa-7"><h3>ログイン</h3></v-btn>
                        <h5 class="white--text text-right mt-5">Ver {{ ver }}</h5>
                    </div>
                </v-col>
            </v-row>
            <!-- =============================================== -->
            <!--
            <v-row class="mt-16">
                <v-col class="text-center">
                    <a href="https://lin.ee/w83X1JA"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0" /></a>
                </v-col>
                <v-col class="text-center">
                    <v-btn to="/manual" color="success" elevation="0" class="rounded-sm">
                        <h4>動画マニュアル</h4>
                    </v-btn>
                </v-col>
            </v-row>
            -->
        </v-container>
    </div>
</template>

<style>
.outer-div {
    display: table;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background: radial-gradient(aquamarine, darkslategray);
}
</style>

<script>
import Version from "@/version.json";
export default {
    name: "Login",
    //#######################################################
    data() {
        return {
            ver: Version.version,
            dbVersion: [],
            LogoImg: require("@/assets/logo.png"),
            username: "",
            password: "",
            dbUsers: [],
            isLoading: false,
        };
    },
    //#######################################################
    created: function () {
        if (this.$localStorage.get("username")) this.username = this.$localStorage.get("username");
        if (this.$localStorage.get("password")) this.password = this.$localStorage.get("password");
        window.scrollTo(0, 0);
    },
    //#######################################################
    mounted: function () {
        this.getVersion();
    },
    //#######################################################
    methods: {
        //===============================================
        // alter table versions add column r4_version varchar(80) not null default '' ;
        //===============================================
        getVersion() {
            this.axios({
                method: "GET",
                url: "/web/versions",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbVersion = response.data.json[0];
                if (this.dbVersion.r4_version) {
                    if (this.dbVersion.r4_version > this.ver) {
                        if (confirm("新しいバージョンがあります。\n更新しますか？")) {
                            this.reloadApp();
                        }
                    }
                }
            });
        },
        //===============================================
        // バージョンアップ（サービスワーカーを解除し、リロードで再登録する）
        async reloadApp() {
            window.navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (let registration of registrations) registration.unregister();
            });
            await new Promise((r) => setTimeout(r, 1000));
            window.location.reload();
            await new Promise((r) => setTimeout(r, 1000));
            alert("更新しました。\n再起動して下さい。");
        },
        //===============================================
        jwtauth() {
            this.username = this.username.trim();
            this.password = this.password.trim();
            if (!this.username) return false;
            if (!this.password) return false;
            //--------------------------
            this.isLoading = true;
            this.axios({
                method: "POST",
                url: "/web/api/authenticate",
                data: {
                    username: this.username,
                    password: this.password,
                    ver: Version.version,
                },
                timeout: 30000, // 30000ms
            })
                .then((response) => {
                    let jwt = response.data.token;
                    this.dbUsers = response.data.json;
                    if (jwt) {
                        this.$localStorage.set("jwt", jwt);
                        this.$localStorage.set("username", this.username);
                        this.$localStorage.set("password", this.password);
                        this.$router.push({ path: "/" });
                    } else {
                        alert("登録がありません。");
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    alert("認証エラー");
                    self.console.log(error);
                    this.isLoading = false;
                });
            this.isLoading = false;
        },
        //===============================================
    },
    //#######################################################
};
</script>
