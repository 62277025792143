<template>
    <v-container fluid class="pa-0">
        <v-overlay :value="isLoading" z-index="1000">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <l-map v-if="dispMap" :zoomControl="false" ref="map" :style="mapCSS()" :zoom="zoom" :minZoom="6" :center="[center.lat, center.lng]" @baselayerchange="baselayerChanged" @update:zoom="zoomUpdated" @update:center="centerUpdated" :options="mapOptions">
            <l-tile-layer v-for="provider in tileProviders" :key="provider.id" :url="provider.url" :visible="provider.visible" :options="provider.options" :name="provider.name" :attribution="provider.attribution" :opacity="0.9" :z-index="30" layer-type="base"></l-tile-layer>
            <l-wms-tile-layer v-for="provider in nowCastProviders" :key="provider.id" :base-url="provider.url" :visible="provider.visible" :opacity="0.9" :z-index="100" layer-type="base2" :options="nowCastOptions"></l-wms-tile-layer>
            <l-control-layers position="topright" hideSingleBase></l-control-layers>
            <!-- ================ -->
            <l-control position="topleft">
                <v-btn @click="nowCastSW()" :color="nowCastVisible ? 'info' : undefined">
                    <v-icon>mdi-weather-pouring</v-icon>
                </v-btn>
            </l-control>
            <!-- ================ -->
            <l-control position="bottomright">
                <div>{{ nowZoom }}</div>
            </l-control>
            <!-- ===== スライダー ===== -->
            <l-control position="bottomleft" v-show="nowCastVisible">
                <div class="mx-9" style="width: 70vw">
                    <v-slider v-model="sliderVal" min="0" :max="sliderMax" @input="nowCastChange()" hide-details color="green darken-1" track-color="green lighten-2" thumb-label="always" thumb-size="50">
                        <template v-slot:thumb-label>
                            <h3>{{ nowCastTime }}</h3>
                        </template>
                    </v-slider>
                </div>
            </l-control>
            <!-- ================ -->
            <l-marker v-for="(p, idx) in markers" :key="idx" :lat-lng="[p.lat, p.lng]" :icon="p.icon">
                <l-popup>
                    <h2>{{ p.device_name }}</h2>
                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: p.device_id } }" color="success">
                        <v-icon>mdi-chart-line</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: p.device_id } }" color="yellow">
                        <v-icon>mdi-camera-outline</v-icon>
                    </v-btn>
                    <v-btn class="ma-1" :to="{ name: 'Playback', params: { device_id: p.device.id } }" color="error" v-show="p.device.has_videocam">
                        <v-icon>mdi-video-outline</v-icon>
                    </v-btn>
                    <h5 v-show="false">フロート= {{ p.device.float_sw ? "High" : "Low" }}</h5>
                    <h5 v-show="false">{{ p.device.deadtime ? $moment(p.device.deadtime).format("YYYY-MM-DD HH:mm") : "---" }}</h5>
                    <h5 class="text-right">{{ $moment(p.device.alived_at).format("YYYY-MM-DD HH:mm") }}</h5>
                </l-popup>
            </l-marker>
            <!-- ================ -->
        </l-map>
    </v-container>
</template>

<style>
.marker-gray {
    filter: brightness(1.1) grayscale(90%);
}
.marker-blue {
    filter: hue-rotate(0deg);
}
.marker-purple {
    filter: hue-rotate(50deg);
}
.marker-pink {
    filter: hue-rotate(100deg);
}
.marker-red {
    filter: hue-rotate(150deg);
}
.marker-brown {
    filter: hue-rotate(180deg);
}
.marker-yellow {
    filter: brightness(1.8) hue-rotate(220deg);
}
.marker-green {
    filter: hue-rotate(270deg);
}
</style>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LControl, LControlLayers, LWMSTileLayer } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
//import "leaflet-sprite";

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    //imagePath: "leaflet/dist/images",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
L.Icon.Default.imagePath = "img";
//--- 色マーカー ---
import "leaflet-sprite/dist/leaflet.sprite.js";
//------------------------------------------
export default {
    name: "DevicesMap",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LControl,
        LControlLayers,
        "l-wms-tile-layer": LWMSTileLayer,
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            //----------------------------------------------
            dispMap: false,
            mapOptions: {
                zoomControl: false,
            },
            //----------------------------------------------
            tileProviders: [
                {
                    id: "t01",
                    name: "標準地図",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    id: "t01a",
                    name: "淡色地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    id: "t02",
                    name: "衛星写真",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
                    options: {},
                },
                {
                    id: "t03",
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    id: "t04",
                    name: "白地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png",
                    options: {
                        maxNativeZoom: 14,
                        maxZoom: 18,
                    },
                },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //----------------------------------------------
            nowCastList: [],
            nowCastProviders: [
                {
                    id: "nc001",
                    url: "https://www.jma.go.jp/bosai/jmatile/data/nowc/20240101235500/none/20240101235500/surf/hrpns/{z}/{x}/{y}.png",
                    visible: true,
                },
            ],
            nowCastVisible: false,
            nowCastListUrlN1: "https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json",
            nowCastListUrlN2: "https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json",
            sliderVal: 0,
            sliderMax: 0,
            nowCastOptions: {
                maxNativeZoom: 10,
                maxZoom: 18,
            },
            //----------------------------------------------
            //url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
            //url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            center: {
                lat: 36.8444607407,
                lng: 138.273925,
            },
            zoom: 5,
            nowZoom: 5,
            markers: [
                //{ lat: 34.2692895, lng: 136.5897455, name: "真珠ようしょく" },
                //{ lat: 34.2825895, lng: 136.776867, name: "定置あみ" }
            ],
            //----------------------------------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: "",
            },
            aspect: "16:9",
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        //---------------------------------
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------
        if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
        if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
        if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
        if (this.$localStorage.get("map.layer")) {
            let layerName = this.$localStorage.get("map.layer");
            for (let layerObj of this.tileProviders) {
                if (layerObj.name == layerName) layerObj.visible = true;
                else layerObj.visible = false;
            }
        }
        this.nowZoom = this.zoom;
        //---------------------------------
        this.isLoading = true;
        await this.getSites();
        await this.getDevices();
        await this.getLastSensings();
        await this.markerRegist();
        this.isLoading = false;
        //---------------------------------
        window.scrollTo(0, 0);
    },
    //========================================================
    watch: {},
    //========================================================
    mounted: async function () {
        window.addEventListener("resize", this.handleResize);
        //---------------------------------
        await this.getNowCastList();
        this.dispMap = true;
        //---------------------------------
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    computed: {
        nowCastTime: function () {
            const idx = this.sliderVal;
            const obj = this.nowCastList[idx];
            const validtime = obj["validtime"];
            return this.$moment(validtime, "YYYYMMDDHHmmss")
                .add(+9, "hour")
                .format("HH:mm");
        },
    },
    //====================================================
    methods: {
        //====================================================
        baselayerChanged: function (layer) {
            //self.console.log(layer.name);
            this.$localStorage.set("map.layer", layer.name);
        },
        zoomUpdated: function (zoom) {
            //self.console.log(zoom);
            this.$localStorage.set("map.zoom", zoom);
            //this.zoom = zoom;
            this.nowZoom = zoom;
        },
        centerUpdated: function (center) {
            //self.console.log(center);
            this.$localStorage.set("map.lat", center.lat);
            this.$localStorage.set("map.lng", center.lng);
        },
        //====================================================
        mapCSS: function () {
            let h = this.windowHeight;
            h = h - 60;
            if (h < 300) h = 300;
            return "height: " + h + "px;";
        },
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async getNowCastList() {
            this.nowCastList = [];
            //--------------------------------------------------
            // 雨雲予測
            await this.axios({
                method: "GET",
                url: this.nowCastListUrlN2,
                timeout: 30000,
            }).then((response) => {
                this.nowCastList.push(...response.data);
            });
            //--------------------------------------------------
            // 雨雲実績
            await this.axios({
                method: "GET",
                url: this.nowCastListUrlN1,
                timeout: 30000,
            }).then((response) => {
                this.nowCastList.push(...response.data);
            });
            this.sliderMax = this.nowCastList.length - 1;
            //--------------------------------------------------
            // 並べ替え
            this.nowCastList.sort(function (a, b) {
                let ret = 0;
                if (a.validtime > b.validtime) ret = 1;
                if (a.validtime < b.validtime) ret = -1;
                if (a.validtime == b.validtime) ret = 0;
                return ret;
            });
            //--------------------------------------------------
            this.nowCastProviders = [];
            for (let idx in this.nowCastList) {
                const obj = this.nowCastList[idx];
                const basetime = obj["basetime"];
                const validtime = obj["validtime"];
                let visible = false;
                if (obj["basetime"] == obj["validtime"]) {
                    this.sliderVal = idx;
                    // visible = true;
                }
                this.nowCastProviders.push({
                    id: "nc" + String(idx),
                    url: "https://www.jma.go.jp/bosai/jmatile/data/nowc/" + basetime + "/none/" + validtime + "/surf/hrpns/{z}/{x}/{y}.png",
                    visible: visible,
                });
            }
            //--------------------------------------------------
            //self.console.log(this.nowCastProviders);
            //await new Promise((r) => setTimeout(r, 100));
        },
        //====================================================
        nowCastSW() {
            this.nowCastVisible = !this.nowCastVisible;
            this.nowCastChange();
        },
        //====================================================
        nowCastChange() {
            for (let idx in this.nowCastProviders) {
                if (idx == this.sliderVal && this.nowCastVisible == true) {
                    this.nowCastProviders[idx].visible = true;
                } else {
                    this.nowCastProviders[idx].visible = false;
                }
            }
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                // params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                // params: { is_active: 1, "_order[id]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getLastSensings() {
            await this.axios({
                method: "GET",
                url: "/web/api/last_sensings",
                // params: { is_active: 1, "_order[id]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbLastSensings = response.data.json;
            });
        },
        //====================================================
        async getRaspiConfig(mac) {
            let ret = null;
            await this.axios({
                method: "GET",
                url: "/web/api/raspi_configs",
                params: {
                    mac: mac,
                    _fields: "mac,float_low_interval,float_high_interval",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) ret = response.data.json[0];
            });
            //await new Promise((r) => setTimeout(r, 100));
            return ret;
        },
        //====================================================
        floatStatus(device_id) {
            let ret = "0";
            for (let obj of this.dbLastSensings) {
                if (device_id == obj.device_id) ret = obj.float_sw;
            }
            return Number(ret);
        },
        //====================================================
        async markerRegist() {
            //------------------------------------------
            const grayIcon = L.icon({
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
                iconSize: [25, 41],
                shadowSize: [41, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                className: "marker-gray", // <= ここでクラス名を指定
            });
            const yellowIcon = L.icon({
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
                iconSize: [25, 41],
                shadowSize: [41, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                className: "marker-yellow", // <= ここでクラス名を指定
            });
            const purpleIcon = L.icon({
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
                iconSize: [25, 41],
                shadowSize: [41, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                className: "marker-purple", // <= ここでクラス名を指定
            });
            //------------------------------------------
            for (let idx in this.dbDevices) {
                let gps = this.dbDevices[idx].gps;
                let device_id = this.dbDevices[idx].id;
                let device_name = this.dbDevices[idx].name;
                let alived_at = this.dbDevices[idx].alived_at;
                let is_rest = this.dbDevices[idx].is_rest;
                let icon = null; //通常水位のマーカーは青
                let float_sw = this.floatStatus(device_id);
                //let mac = this.dbDevices[idx].mac;
                //let raspiConfig = await this.getRaspiConfig(mac);
                let raspiConfig = {
                    float_high_interval: 60,
                    float_low_interval: 10,
                };

                this.dbDevices[idx].float_sw = float_sw;

                // self.console.log("============================");
                // self.console.log(raspiConfig);
                // self.console.log({ device_name });
                // self.console.log({ alived_at });

                if (float_sw == "1") icon = self.L.spriteIcon("red"); //危険水位のマーカーは赤
                if (this.$moment(alived_at) < this.$moment().add(-2, "hours")) icon = self.L.spriteIcon("yellow"); //2時間通信無しは黄色
                if (raspiConfig) {
                    const now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
                    let deadtime = this.$moment(alived_at).format("YYYY-MM-DD HH:mm:ss");
                    const low = raspiConfig.float_low_interval;
                    const high = raspiConfig.float_high_interval;
                    if (float_sw == "1") {
                        deadtime = this.$moment(alived_at)
                            .add(high * 2, "minutes")
                            .add(2, "minutes")
                            .format("YYYY-MM-DD HH:mm:ss");
                    } else {
                        deadtime = this.$moment(alived_at)
                            .add(low * 2, "minutes")
                            .add(120, "minutes")
                            .format("YYYY-MM-DD HH:mm:ss");
                    }
                    if (deadtime < now) icon = yellowIcon;
                    this.dbDevices[idx].deadtime = deadtime;
                } else {
                    icon = purpleIcon;
                }
                if (is_rest == 1) icon = grayIcon;
                //---------------------------------------------
                if (gps) {
                    let latlon = gps.split(",");
                    let lat = Number(latlon[0]);
                    let lng = Number(latlon[1]);
                    this.markers.push({
                        lat: lat,
                        lng: lng,
                        device_id: device_id,
                        device_name: device_name,
                        icon: icon,
                        device: JSON.parse(JSON.stringify(this.dbDevices[idx])),
                    });
                }
            }
        },
        //====================================================
    },
    //========================================================
};
</script>
